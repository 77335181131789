.available_coupons {
    margin-top: .5rem;
    margin-bottom: .5rem;
}

.viewall_coupon{
    flex-grow: 0;
    margin-left: auto;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

.code_applied{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.2rem;
    padding: 5px;
    border: var(--border-size) solid var(--border-color);
    border-radius: calc(var(--border-radius)/2);
    border-style: dashed;
    color: var(--text-color-light);
    background-color: var(--background-color-light);
}

.coupon_input input{
    background-color: var(--background-color-light);
    border: var(--border-size) solid var(--border-color);
    color: var(--text-color);
}