.bot_card{
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: var(--border-size) solid var(--border-color);
    border-radius: var(--border-radius);
    color: var(--text-color);
    background-color: var(--background-color-light);
    transition: .5s ease;
}

.bot_card:hover{
    transform: scale(1.02);
    box-shadow: none;
}

.bot_card a{
    text-decoration: none;
    color: var(--text-color);
}

.bot_title{
    margin-top: .75rem;
    align-self: flex-start;
}

.bot_description{
    margin-bottom: .625rem;
    align-self: flex-start;
    color: var(--text-color-light);
}

@media (max-width: 576px) {
    .bot_title{
        margin-top: 0;
    }
    .bot_card{
        width: 100%;
        flex-direction: row;
    }
    .bot_card_body{
        margin-left: var(--margin);
    }
}
