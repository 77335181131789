.not_found_animation{
    width: 500px;
    height: 100%;
}

.not_found{
    margin: auto;
}

@media (max-width: 768px) {
    .not_found_animation{
        width: 300px;
    }
}