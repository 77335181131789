.coupon{
    display: flex;
    flex-direction: column;
    border: var(--border-size) solid var(--border-color);
    border-radius: calc(var(--border-radius)/2);
    padding: .5rem;
    margin-top: .5rem;
    margin-bottom: .5rem;
    background-color: var(--background-color-light);
}

.coupon_icon{
    height: 1rem;
    width: 1rem;
}

.coupon_code{
    display: inline-block;
    padding: 0.3rem;
    margin: 0.5rem 0;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    color: var(--text-color);
    letter-spacing: 2px;
    flex-grow: 0;
    text-transform: uppercase;
    background-color: var(--code-color);
}

.coupon_details_btn{
    flex-grow: 0;
    color: var(--text-color-light);
    font-weight: 500;
    cursor: pointer;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

.coupon_details{
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0,1,0,1);
}

.coupon_details.show{
    height: auto;
    max-height: 9999px;
    transition: all 0.5s cubic-bezier(1,0,1,0);
}


.details_list{
    color: var(--text-color-light);
    margin: 0.3rem;
}


.coupon_applied{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.2rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: var(--success-color);
    background-color: #cbeacc;
    border-radius: 5px;
    padding: 5px;
    margin-top: .5rem;
}

.celebrate{
    display: none;
    position: fixed;
    top: 1%;
    left: 50%;
    transform: translate(-50%,0%);
    height: 15rem;
    width: 15rem;
}

.celebrate svg{
    border-radius: 50%;
}

.celebrate::after{
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: inset 10px 24px 40px 0px rgba(93, 93, 93, 0), 
        inset -10px -24px 40px 0px rgba(93, 93, 93, 0), 
        inset 20px -10px 40px 0px rgba(93, 93, 93, 0), 
        inset -20px 10px 40px 0px rgba(93, 93, 93, 0);
}