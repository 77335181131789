.summary{
    display: flex;
    flex-direction: column;
    gap: .5rem;
    border: var(--border-size) solid var(--border-color);
    padding: var(--padding);
    margin: var(--margin);
    border-radius: var(--border-radius);
    max-width: 26rem;

    background-color: var(--background-color);
    color: var(--text-color);
}
