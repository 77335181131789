main{
    padding: 1% 10%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}

main > div{
    flex-grow: 1;
}



@media (max-width: 768px) {
    main{
        padding: 1% 5%;
    }
}

@media (max-width: 425px) {
    main{
        padding: 1% 2%;
    }
}