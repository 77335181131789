.warning-animation{
    width: 300px;
    height: 100%;
}

.invalid-payment-link{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    padding: calc(var(--padding)*2);
}


@media (max-width: 768px) {
    .warning-animation{
        width: 150px;
    }
}