.payment_methods{
    margin: var(--margin);
    max-width: 30rem;
    
    border: var(--border-size) solid var(--border-color);
    border-radius: var(--border-radius);
    padding: var(--padding);
    background-color: var(--background-color);
    color: var(--text-color);
}

.payment_methods.error{
    border: var(--border-size) solid var(--error-color);
}

.subheader{
    color: var(--text-color-light);
}

.payment_methods_list{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: var(--margin) 0;
}

.sizedBox{
    height : 3rem;
}