@font-face {
    font-family: 'Product Sans';
    src: url('./assets/product_sans_regular.ttf');
}

*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    max-width: 100%;
}

body{
    font-family: 'Product Sans', sans-serif;
}

:root{
    /* variables */
    --border-radius: 10px;
    --padding: 1rem;
    --margin: 1rem;
    --border-size: 1.5px;

    /* color variables */
    --text-color: #18181b;
    --text-color-light: #52525b;
    --border-color: #e4e4e7;
    --primary-color: #4c6ef5;
    --background-color: #fff;
    --background-color-light: #f8f9fb;
    --error-color: #fa5252;
    --success-color : #4caf50;
    --code-color: #fff;
    --accent-color: #7f97f8;
}

:root[theme="dark"]{
    --text-color: #fff;
    --text-color-light: #a6a7ab;
    --border-color: #373a40;
    --primary-color: #4c6ef5;
    /* --primary-color: #9b4cf5; */
    --background-color: #1a1b1e;
    --background-color-light: #25262b;
    --error-color: #e03131;
    --success-color : #118b67;
    --code-color : #36383e;
}

/* .light{
    --text-color: #18181b;
    --text-color-light: #52525b;
    --border-color: #e4e4e7;
    --primary-color: #4c6ef5;
    --background-color: #fff;
    --background-color-light: #f8f9fb;
    --error-color: #fa5252;
    --success-color : #4caf50;
    --code-color: #fff;
}

.dark{
    --text-color: #fff;
    --text-color-light: #a6a7ab;
    --border-color: #373a40;
    --primary-color: #4c6ef5;
    --primary-color: #9b4cf5;
    --background-color: #1a1b1e;
    --background-color-light: #25262b;
    --error-color: #e03131;
    --success-color : #118b67;
    --code-color : #36383e;
} */


@media (max-width: 768px){
    body{
        --padding: .8rem;
        --margin: .8rem;
        --border-size: 1.5px;
    }
}

@media (max-width: 425px){
    body{
        --padding: .5rem;
        --margin: .5rem;
        --border-size: 1px;
    }
}