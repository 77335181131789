header{
    border-bottom: var(--border-size) solid var(--border-color);
    padding: 0.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

header .theme{
    display: flex;
    align-items: center;
    border: var(--border-size) solid var(--border-color);
    border-radius: var(--border-radius);
    padding: 0.5rem;
    cursor: pointer;
    color: var(--text-color-light);
    -webkit-tap-highlight-color: transparent;
}

header a{
    text-decoration: none;
}

.brand{
    display: flex;
    align-items: center;
    margin-left: 2rem;
}

.brand_logo{
    height: 50px;
}

.brand_name{
    font-size: 1.25rem;
    font-weight: 600;
    margin-left: 0.5rem;
    /* background : linear-gradient(90deg, rgba(28,126,214,1) 0%, rgba(32,160,210,1) 20%, rgba(34,184,207,1) 79%); */
    background : linear-gradient(90deg, rgb(12, 58, 98) 0%, rgb(23, 112, 147) 20%, rgb(37, 195, 219) 79%);
    -webkit-background-clip: text;
    background-clip: text;
    background-size: 200% 400%;
    -webkit-text-fill-color: transparent;
    -webkit-animation: animate-text 5s ease infinite;
    -moz-animation: animate-text 5s ease infinite;
    -o-animation: animate-text 5s ease infinite;
    animation: animate-text 5s ease infinite;
}

.right_section{
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-right: 2rem;
}

@-webkit-keyframes animate-text {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-moz-keyframes animate-text {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-o-keyframes animate-text {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes animate-text {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}


@media (max-width: 768px) {
    .right_section{
        gap: 0.5rem;
        margin-right: 1rem;
    }
    .brand{
        margin-left: 1rem;
    }
}