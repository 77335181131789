.loading_animation{
    width: 500px;
    height: 100%;
}

.loading{
    margin: auto;
}

@media (max-width: 768px) {
    .loading_animation{
        width: 300px;
    }
}