.homepage-content{
    color: var(--text-color);
    background-color: var(--background-color);
    padding: 1% 8%;
}

.welcome {
    margin-top: 6rem;
    margin-bottom: 6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}

.title_text{
    text-align: center;
    font-size: 2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-weight: 600;
}

.welcome_text{
    max-width: 700px;
    font-size: 2.5rem;
}

.welcome img {
    width: 450px;
}

#service_header, #get-in-touch-header, #process-header {
    margin-bottom: 2rem;
   text-align: center;
   color: var(--primary-color);
    
}
.services {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
}

.services div{
    max-width: 500px;
}

.homepage-content p{
    padding: var(--padding) 0;
    font-size: 1.8rem;
}


.bots{
    display: flex;
    flex-wrap: wrap;
    gap: .5rem;
    justify-content: center;
}

.our_bots,.our_services{
    margin-bottom: 3rem;
}


.service_pricing_cards{
    display: flex;
    gap: 2rem;
    justify-content: center;
    flex-wrap: wrap;
    margin: 2rem 0;
}


@media (max-width: 1024px) {
    .homepage-content{
        padding: 1% 5%;
    }
    .welcome{
        justify-content: center;
        flex-wrap: wrap-reverse;
    }
    .welcome img {
        width: 350px;
    }
    .welcome_text{
        font-size: 2rem;
    }
    .title_text{
        font-size: 1.8rem;
    }
    .bots{
        justify-content: center;
    }
}


@media (max-width: 768px) {
    .homepage-content{
        padding: 1% 5%;
    }
    .welcome img {
        width: 300px;
    }
    .welcome_text{
        font-size: 1.8rem;
    }
    .title_text{
        font-size: 1.5rem;
    }
}

@media (max-width: 425px) {
    .homepage-content{
        padding: 1% 2%;
    }
    .welcome img {
        width: 250px;
    }
    .welcome_text{
        font-size: 1.5rem;
    }
    .title_text{
        font-size: 1.2rem;
    }
}