footer{
    border-top: var(--border-size) solid var(--border-color);
    /* padding: 1rem; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5rem;
    margin-top: auto;
}

footer .links{
    display: flex;
    align-items: center;
    gap: 1rem;
}

footer .links a{
    text-decoration: none;
    color: var(--text-color);
}

footer .links a:hover{
    color: var(--primary-color);
    /* text-decoration: underline; */
}