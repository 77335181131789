.login{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.pincode{
    display: flex;
    justify-content: center;
}

.command{
    color: #4c4cfc;
}