.service_card{
    margin: 1rem;
    border: var(--border-size) solid var(--border-color);
    border-radius: var(--border-radius);
    color: var(--text-color);
    background-color: var(--background-color-light);
    transition: .5s ease;
}

.service_card:hover{
    transform: scale(1.02);
    box-shadow: none;
}

.service_title{
    color: var(--primary-color);
    font-size: 2.5rem;
    margin: 1rem;
}

.service_description{
    color: var(--text-color-light);
}