.coins{
    padding: calc(var(--padding)/2);
    color: var(--text-color);
    border: var(--border-size) solid var(--border-color);
    background-color: var(--background-color-light);
    border-radius: calc(var(--border-radius) / 2);
    margin: var(--margin) 0;
    cursor: pointer;
    transition: 0.5s ease;
}

.coin-code{
    color: var(--text-color-light);
}

.coin-selected{
    border-color: var(--primary-color);
}

.coins img{
    height: 30px;
    width: 30px;
}

.coin-selected-icon{
    margin-left: auto;
}
