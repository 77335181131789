.plan_details{
    display: grid;
    grid-template-columns: 1fr 3fr;
    align-items: center;
    gap: .5rem;
    border: var(--border-size) solid var(--border-color);
    padding: .5rem;
    border-radius: calc(var(--border-radius)/2);
    background-color: var(--background-color-light);
    border-style: dashed;
}