.payment_method{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: var(--border-size) solid var(--border-color);
    border-radius: calc(var(--border-radius)/2);
    padding: var(--padding);
    cursor: pointer;
    transition: ease-in-out 0.2s;
    background-color: var(--background-color-light);
    color: var(--text-color);
    -webkit-tap-highlight-color: transparent;
}

.payment_method_active{
    border-color: var(--primary-color);
}

input[type="radio"]{
    accent-color: var(--primary-color);
    background-color: var(--background-color);
    cursor: pointer;
    height: 1rem;
    width: 1rem;
    /* border: var(--border-size) solid var(--border-color); */
}


.payment_method label{
    cursor: pointer;
}

.payment_method div{
    display: flex;
    align-items: center;
    gap: 1rem;
}

.payment_method img{
    width: 6rem;
    height: auto;
}


@media (max-width: 768px){
    .payment_method img{
        width: 5rem;
    }
}

@media (max-width: 400px){
    .payment_method img{
        width: 2.5rem;
        height: 2.5rem;
    }
}

