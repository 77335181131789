.refund-policy{
    margin: 5% auto;
    padding: 1% 15%;
    background-color: var(--background-color);
    color: var(--text-color);
}

.refund-policy h1{
    font-size: 2.5rem;
    font-weight: 600;
    margin-bottom: 2%;
}

.refund-policy p{
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 2%;
    color: var(--text-color-light);
}

.refund-policy h3{
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 2%;
}




@media (max-width: 768px) {
    .refund-policy{
        padding: 1% 5%;
    }

    .refund-policy h1{
        font-size: 2rem;
        margin-bottom: 1.8%;
    }

    .refund-policy p{
        font-size: 1.1rem;
        margin-bottom: 1.8%;
    }

    .refund-policy h3{
        font-size: 1.4rem;
        margin-bottom: 1.8%;
    }
}

@media (max-width: 425px) {
    .refund-policy{
        padding: 1% 3%;
    }

    .refund-policy h1{
        font-size: 1.5rem;
        margin-bottom: 1.5%;
    }

    .refund-policy p{
        font-size: 1rem;
        margin-bottom: 1.5%;
    }

    .refund-policy h3{
        font-size: 1.2rem;
        margin-bottom: 1.5%;
    }
}