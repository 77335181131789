.something_went_wrong_animation{
    width: 500px;
    height: 100%;
}

.something_went_wrong{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin: auto;
}

.try_reloading_text{
    color: var(--text-color);
    text-align: center;
}

@media (max-width: 768px) {
    .something_went_wrong_animation{
        width: 300px;
    }
}