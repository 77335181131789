.pricing-card{
    border: var(--border-size) solid var(--border-color);
    border-radius: var(--border-radius);
    color: var(--text-color);
    background-color: var(--background-color-light);
    padding: var(--padding);
    min-width: 280px;
    max-width: 280px;
    display: flex;
    flex-direction: column;
    transition: .5s ease;
}

.pricing-card:hover{
    transform: scale(1.02);
    box-shadow: none;
}

.pricing-card-title{
    font-size: 1.5rem;
    font-weight: 500;
}

.pricing-card-description{
    color: var(--text-color-light);
}

.pricing-card-price{
    font-size: 2rem;
    font-weight: 600;
    margin: var(--margin) 0;
}

.pricing-card-features{
    margin-top: var(--margin);
    margin-bottom: var(--margin);
}


.pricing-card-feature-icon{
    color: var(--success-color);
}

.pricing-card-button{
    margin-top: auto;
}

@media (max-width: 1024px) {
}

@media (max-width: 768px) {
}

@media (max-width: 425px) {
}