.price_item{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.price_title{
    color: var(--text-color-light);
    font-weight: 500;
}

.price{
    color: var(--text-color-light);
    font-weight: 500;
}

.grand_total_title, .grand_total_price{
    margin-top: .5rem;
    font-weight: 600;
    color: var(--text-color);
    font-size: 1rem;
}

.coupon_title, .coupon_price{
    color: var(--primary-color);
}